/* Scrollbars */
@media (min-width: 600px) {
	html {
		overflow-x: hidden;
		overflow-y: hidden;
	}

	::-webkit-scrollbar {
		width: 9px;
		height: 9px;
	}

	::-webkit-scrollbar-button {
		width: 0px;
		height: 0px;
	}

	::-webkit-scrollbar-thumb {
		background: #aaa;
		border: 0px none #ffffff;
		border-radius: 50px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #999;
	}

	::-webkit-scrollbar-thumb:active {
		background: #909090;
	}

	::-webkit-scrollbar-track {
		background: #fff;
		border: 0px none #ffffff;
		border-radius: 50px;
	}

	::-webkit-scrollbar-track:hover {
		background: #efefef;
	}

	::-webkit-scrollbar-track:active {
		background: #eeeeee;
	}

	::-webkit-scrollbar-corner {
		background: transparent;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
